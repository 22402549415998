var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-action',{ref:"modal-confirm",attrs:{"icon":"confirmation","title":_vm.modalActionData.title,"description":_vm.modalActionData.description,"visible":_vm.showModalConfirm,"text-secondary":_vm.modalActionData.textSecondary,"text-primary":_vm.modalActionData.textPrimary},on:{"cancel":function () { return (_vm.showModalConfirm = false); },"action":_vm.doActionModal}}),_c('modal',{ref:"modal-success",attrs:{"visible":_vm.showModalSuccess,"title":"Updated","type":"cancel","body":"This SKU Voucher is successfully updated"},on:{"change":_vm.onCloseSuccessModal}}),_c('h1',{staticClass:"title"},[_vm._v(" Edit SKU Voucher ")]),_c('div',{staticClass:"flex flex-col w-8/12"},[_c('VoucherTextInput',{staticStyle:{"margin-bottom":"1.5rem"},attrs:{"fieldLabel":"SKU","required":true,"id":"sku-code","name":"skuCode","type":"text","placeholder":"SKU Code","errorMessage":_vm.$v.form.skuCode.$dirty &&
          !_vm.$v.form.skuCode.required &&
           _vm.$v.form.skuCode.minLength
            ? 'field is required'
              : undefined},model:{value:(_vm.$v.form.skuCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.skuCode, "$model", $$v)},expression:"$v.form.skuCode.$model"}}),_c('VoucherTextInput',{staticStyle:{"margin-bottom":"1.5rem"},attrs:{"fieldLabel":"Voucher Name","required":true,"fieldDescription":"You can use alphabet and numbers","withCounter":true,"maxCount":_vm.maxCount,"id":"voucher-name","name":"voucherName","type":"text","placeholder":"Voucher Name","errorMessage":_vm.$v.form.voucherName.$dirty && !_vm.$v.form.voucherName.required && _vm.$v.form.voucherName.minLength
          ? 'field is required'
          : !_vm.$v.form.voucherName.maxLength ?  'maximal voucher name length is ' + _vm.maxCount: undefined},model:{value:(_vm.$v.form.voucherName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.voucherName, "$model", $$v)},expression:"$v.form.voucherName.$model"}}),_c('VoucherTextInput',{attrs:{"fieldLabel":"Amount","required":true,"id":"voucher-sku-amount","name":"voucherSkuAmount","type":"currency","placeholder":"Input number","errorMessage":_vm.$v.form.amount.$dirty && _vm.$v.form.amount.$invalid
          ? 'minimal voucher amount is Rp1'
          : undefined},model:{value:(_vm.$v.form.amount.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.amount, "$model", $$v)},expression:"$v.form.amount.$model"}})],1),_c('nav',{staticClass:"flex flex-row items-center justify-center w-full",staticStyle:{"padding-top":"160px"}},[_c('Button',{staticClass:"mr-4 text-sm",attrs:{"route-to":"/voucher/voucher-config","button-style":"outline"}},[_vm._v("Cancel ")]),_c('Button',{staticClass:"text-sm",attrs:{"disabled":_vm.$v.form.$invalid},on:{"click":_vm.actionShowConfirm}},[_vm._v("Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }